.cssversion_content_global{
  background-color: #D0D3D4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 1px;
  position: fixed;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cssversion_subcontent{
  background: white;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 0px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.span-tmp {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.input-global-v2 {
  display: inline-block;
  border: none;
  color: #626567;
  text-overflow: clip;
  font-size: 9pt;
}
.cssversion_menu{
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.cssversion_inlinetable{
  display: inline-table;
}
.cssversion_colorblanco{
  background-color:white;
}
.cssversion_colorprimario{
  background-color: var(--color-primario)
}
.cssversion_colorwhite{
  background-color: white;
}
.cssversion_pw100{
  width: 100%;
}
.cssversion_pw90{
  width: 90%;
}
.cssversion_pw80{
  width: 80%;
}
.cssversion_pw85{
  width: 85%;
}
.cssversion_pw75{
  width: 75%;
}
.cssversion_pw70{
  width: 70%;
}
.cssversion_pw60 {
  width: 60%;
}
.cssversion_pw55{
  width: 55%;
}
.cssversion_pw40{
  width: 40%;
}
.cssversion_pw30{
  width: 30%;
}
.cssversion_pw25{
  width: 25%;
}
.cssversion_pw20{
  width: 20%;
}
.cssversion_pw15{
  width: 15%;
}
.cssversion_pw10{
  width: 10%;
}
.cssversion_ph100{
  height: 100%;
}
.cssversion_ph90{
  height: 90%;
}
.cssversion_ph85{
  height: 85%;
}
.cssversion_ph80{
  height: 80%;
}
.cssversion_ph75{
  height: 75%;
}
.cssversion_ph70{
  height: 70%;
}
.cssversion_pw50{
  width: 50%;
}
.cssversion_ph60{
  height: 60%;
}
.cssversion_ph50{
  height: 50%;
}
.cssversion_ph40{
  height: 40%;
}
.cssversion_ph30{
  height: 30%;
}
.cssversion_ph20{
  height: 20%;
}
.cssversion_ph15{
  height: 15%;
}
.cssversion_ph10{
  height: 10%;
}
.cssversion_pxw700{
  width:700px;
}
.cssversion_pxw200{
  width:200px;
}
.cssversion_pxw150{
  width:150px;
}
.cssversion_pxw250{
  width:250px;
}
.cssversion_pxw300{
  width:300px;
}
.cssversion_pxw400{
  width:400px;
}
.cssversion_pxw70{
  width:63px;
}
.cssversion_pxw60{
  width:60px;
}
.cssversion_pxw50{
  width:50px;
}
.cssversion_pxw40{
  width:40px;
}

.cssversion_pxh400{
  height: 400px;
}
.cssversion_pxh250{
  height: 250px;
}

.cssversion_pxh200{
  height: 200px;
}
.cssversion_pxh110{
  height: 111px;
}
.cssversion_pxh100{
  height: 100px;
}
.cssversion_pxh90 {
  height: 90px;
}
.cssversion_pxh80{
  height: 80px;
}
.cssversion_pxh70 {
  height: 70px;
}
.cssversion_pxh60{
  height: 60px;
}
.cssversion_pxh50{
  height: 50px;
}
.cssversion_pxh40{
  height: 40px;
}
.cssversion_pxh35{
  height: 35px;
}
.cssversion_pxh30{
  height: 30px;
}
.cssversion_pxh20{
  height: 18px;
}
.cssversion_padding10{
  padding: 10px;
}
.cssversion_padding5{
  padding: 5px;
}
.cssversion_contentCenter{
  justify-content: center;
  align-items: center;
  display: flex;
}
.cssversion_textCenter{
  text-align: center;
}
.cssversion_contentRight{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
}

.cssversion_footer{
  bottom: 10px;
  position: fixed;
}

.cssversion_span-title-sucursal {
  color: var(--color_terciario);
  font-size: 20pt;
  letter-spacing: 0;
  text-shadow: -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black, 2px 2px 2px black;
  font-weight: 900;
  text-align: center;
 }
 .cssversion_img:hover {
   filter: blur(0px);
 }
 .cssversion_img {
   filter: blur(3px);
   -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
 }

 @keyframes rotate {
 	100% {
 		transform: rotate(1turn);
 	}
 }
 .cssversion_div_pestania_activa {
    border-top: 3px solid #797979;
    border-left: 3px solid #797979;
    border-right: 3px solid #797979;
    border-top-left-radius: calc(.70rem - 1px);
    border-top-right-radius: calc(.70rem - 1px);
    background: #797979;
    color: white;
}
.cssversion_div_pestania_activa_disabled {
   color: #797979;
   background: white;
}

 .cssversion_div_forma_pedido {
    border: 3px solid var(--color-primario);
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 0 20% 20% 0;
}
.cssversion_div_option {
  border-bottom:  1px solid #797979;
  border-left: 1px solid #797979;
  border-top-left-radius: calc(.70rem - 1px);
  color: 797979;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cssversion_div_forma_pedido_ {
    text-align: center;
    border-radius: 20% 0 0 20%;
    border: 3px solid var(--color-primario);
    background-color: var(--color-primario);
    color: var(--color_terciario);
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18pt;
}
.ccsversion_div_bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.span-sin-formato{/*OK*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lbl_chart_2 {
   width: 100%;
   height: 500px;
   align-content:center
}
.lbl_chart_2_sub {
   width: 95%;
   height: 80%;
   align-content:center
}
