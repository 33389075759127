@import "./mixin";

.default-theme {

    $sidebar-bg-color: #1d1d1d;
    $primary-color: #adadad;
    $primary-highlight-color: #d8d8d8;
    $border-color: #2b2b2b;
    $submenu-bg-color: #2b2b2b;
    $menu-icon-bg-color: #2b2b2b;
    $menu-icon-color: #818896;
    $menu-icon-highlight-color: #22ff16;
    $menu-header-color: #868686;
    $search-input-bg-color: #2b2b2b;
    $footer-bg-color: #2b2b2b;
    $shadow-color: #1d1d1d;
    $scrollbar-color: #525965;

    @include themes($sidebar-bg-color,
    $primary-color,
    $primary-highlight-color,
    $border-color,
    $submenu-bg-color,
    $menu-icon-bg-color,
    $menu-icon-color,
    $menu-icon-highlight-color,
    $menu-header-color,
    $search-input-bg-color,
    $footer-bg-color,
    $shadow-color,
    $scrollbar-color);    

}

.chiller-theme {

    $sidebar-bg-color: #2A2C2B;
    $primary-color: #D9CB9E;
    $primary-highlight-color: #ffe79a;
    $border-color: #374140;
    $submenu-bg-color: #374140;
    $menu-icon-bg-color: #374140;
    $menu-icon-color: #D9CB9E;
    $menu-icon-highlight-color: #ffbe00;
    $menu-header-color: #a29464;
    $search-input-bg-color: #374140;
    $footer-bg-color: #374140;
    $shadow-color: #2A2C2B;
    $scrollbar-color: #525965;

    @include themes($sidebar-bg-color,
    $primary-color,
    $primary-highlight-color,
    $border-color,
    $submenu-bg-color,
    $menu-icon-bg-color,
    $menu-icon-color,
    $menu-icon-highlight-color,
    $menu-header-color,
    $search-input-bg-color,
    $footer-bg-color,
    $shadow-color,
    $scrollbar-color);

}

.legacy-theme {

    $sidebar-bg-color: #1e2229;
    $primary-color: #818896;
    $primary-highlight-color: #b8bfce;
    $border-color: #2e333c;
    $submenu-bg-color: #2e333c;
    $menu-icon-bg-color: #2e333c;
    $menu-icon-color: #818896;
    $menu-icon-highlight-color: #16c7ff;
    $menu-header-color: #646e80;
    $search-input-bg-color: #2e333c;
    $footer-bg-color: #22262d;
    $shadow-color: #16191f;
    $scrollbar-color: #525965;

    @include themes($sidebar-bg-color,
    $primary-color,
    $primary-highlight-color,
    $border-color,
    $submenu-bg-color,
    $menu-icon-bg-color,
    $menu-icon-color,
    $menu-icon-highlight-color,
    $menu-header-color,
    $search-input-bg-color,
    $footer-bg-color,
    $shadow-color,
    $scrollbar-color);

}


.cool-theme {

    $sidebar-bg-color: #38373D;
    $primary-color: #918F9E;
    $primary-highlight-color: #b3b8c1;
    $border-color: #46454c;
    $submenu-bg-color: #46454c;
    $menu-icon-bg-color: #46454c;
    $menu-icon-color: #918F9E;
    $menu-icon-highlight-color: #fe6fff;
    $menu-header-color: #747479;
    $search-input-bg-color: #46454c;
    $footer-bg-color: #414046;
    $shadow-color: #2a292d;
    $scrollbar-color: #918F9E;

    @include themes($sidebar-bg-color,
    $primary-color,
    $primary-highlight-color,
    $border-color,
    $submenu-bg-color,
    $menu-icon-bg-color,
    $menu-icon-color,
    $menu-icon-highlight-color,
    $menu-header-color,
    $search-input-bg-color,
    $footer-bg-color,
    $shadow-color,
    $scrollbar-color);

}


.ice-theme {

    $sidebar-bg-color: #2B3A42;
    $primary-color: #9eb7c3;
    $primary-highlight-color: #EFEFEF;
    $border-color: #3a4d56;
    $submenu-bg-color: #3a4d56;
    $menu-icon-bg-color: #3a4d56;
    $menu-icon-color: #bdd4de;
    $menu-icon-highlight-color: #38fbc7;
    $menu-header-color: #6c7b88;
    $search-input-bg-color: #3a4d56;
    $footer-bg-color: #2f3f48;
    $shadow-color: #2b3a42;
    $scrollbar-color: #8998a5;

    @include themes($sidebar-bg-color,
    $primary-color,
    $primary-highlight-color,
    $border-color,
    $submenu-bg-color,
    $menu-icon-bg-color,
    $menu-icon-color,
    $menu-icon-highlight-color,
    $menu-header-color,
    $search-input-bg-color,
    $footer-bg-color,
    $shadow-color,
    $scrollbar-color);

}

.light-theme {

    $sidebar-bg-color: #ececec;
    $primary-color: #74726E;
    $primary-highlight-color: #424242;
    $border-color: #f9f9f9;
    $submenu-bg-color: #f9f9f9;
    $menu-icon-bg-color: #f9f9f9;
    $menu-icon-color: #bdd4de;
    $menu-icon-highlight-color: #00a9fd;
    $menu-header-color: #6c7b88;
    $search-input-bg-color: #f9f9f9;
    $footer-bg-color: #f9f9f9;
    $shadow-color: #ececec;
    $scrollbar-color: #A4A29E;

    @include themes($sidebar-bg-color,
    $primary-color,
    $primary-highlight-color,
    $border-color,
    $submenu-bg-color,
    $menu-icon-bg-color,
    $menu-icon-color,
    $menu-icon-highlight-color,
    $menu-header-color,
    $search-input-bg-color,
    $footer-bg-color,
    $shadow-color,
    $scrollbar-color);

}