.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
  }
  .date-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    width: 80%;
  }
  .date-filter label {
    margin-right: 10px;
    font-size: 1em;
  }
  .date-filter input {
    padding: 5px;
    font-size: 1em;
  }
   
  .gauge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .gauge {
    position: relative;
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .gauge-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .gauge svg {
    width: 100%;
    height: 100%;
  }
  .gauge .gauge-score {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
    color: #ffbb00;
  }
  .gauge .gauge-change {
    position: absolute;
    top: 90%;
    font-size: 1em;
    color: green;
  }
  .stats {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0;
  }
  .stat-item {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    position: relative;
    margin: 5px;
  }
  .promoters {
    background-color: #00C851;
    color: white;
  }
  .passives {
    background-color: #FFBB33;
    color: white;
  }
  .detractors {
    background-color: #ff4444;
    color: white;
  }
  .total-surveys {
    background-color: #33b5e5;
    color: white;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    margin-left: 20px;
  }
  .stat-item img {
    /* width: 100px; */
    height: 50px;
    margin-right: 10px;
  }
  .stat-item .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .stat-item .details span {
    display: block;
  }
  .stat-item .range {
    font-size: 0.9em;
    color: #555;
  }
  .locations {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
  }
  .locations div {
    width: 100%;
  }
  .chart-container {
    position: relative;
    width: 100%;
    height: 400px;
  }
  .disclaimer {
    font-size: 1em;
    color: #555;
    text-align: center;
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    .stats {
      flex-direction: column;
      align-items: center;
    }
    .stat-item {
      width: 80%;
      margin-bottom: 10px;
    }
    .locations {
      flex-direction: column;
      align-items: center;
    }
    .locations div {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  .table-survey {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .table-survey th, .table-survey td {
    padding: 15px;
    text-align: left;
  }
  
  .table-survey thead {
    background-color: #28a745;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .table-survey tbody tr {
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
  }
  
  .table-survey tbody tr:last-child {
    border-bottom: none;
  }
  
  .table-survey tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Estilos específicos para los botones dentro de GetSurvey_v5 */
  .button-survey {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button-survey:hover {
    background-color: #218838;
  }
  
  .accordion-content-survey {
    background-color: #f9f9f9;
    padding: 20px;
    display: none;
    border-top: 1px solid #ddd;
  }
  
  .accordion-content-survey table {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
  }
  
  .accordion-content-survey th {
    background-color: #28a745;
    color: white;
  }
  
  .accordion-content-survey td {
    padding: 10px;
  }
  
  .accordion-expanded-survey .accordion-content-survey {
    display: table-row;
    animation: expand 0.3s ease-in-out;
  }
  
  @keyframes expand {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }