.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formulario{
    border:solid 2px #000000;
    border-radius: 15px;
    box-shadow: 1px 1px 10px #828080;
    background-color:#dcdcdc;
    /*margin:0 auto;*/
    width :400px;
    padding:14px;
    display: table;
}
.formulario label{
    display:block;
    font-weight:bold;
    text-align:right;
    width:140px;
    float:left;

}
.formulario select {
    float:left;
    font-size:12px;
    padding:4px 2px;
    border:1px solid #708090;
    width:200px;
      margin:2px 0 5px 5px;

}

.formulario button {
    float:left;
    font-size:12px;
    padding:4px 2px;
    border:1px solid #708090;
    width:200px;
    margin:2px 0 5px 5px;

}
.formulario input {
    float:left;
    font-size:12px;
    padding:4px 2px;
    border:1px solid #708090;
    width:200px;
    margin:2px 0 5px 5px;

}
table.table_v2 {
  border: 3px solid black;
  background-color: #EEEEEE;
  border-collapse: collapse;
}
table.table_v2 td, table.table_v2 th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table.table_v2 tbody td {
  font-size: 13px;
}
table.table_v2 tr:nth-child(even) {
  background: #ffffff;
}
table.table_v2 thead {
  background: #62676b;
  /*background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  //background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  //background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);*/
  border-bottom: 2px solid #444444;
}
table.table_v2 thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}
table.table_v2 thead th:first-child {
  border-left: none;
}

table.table_v2 tfoot td {
  font-size: 14px;
}
table.table_v2 tfoot .links {
  text-align: right;
}
table.table_v2 tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

.parent {
  display: grid;
  place-items: center;
}
.white {
  background-color: white;
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "lbl_global";
}

.lbl_global {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "lbl_uno" "lbl_dos" "lbl_tres";
  grid-area: lbl_global;
  height: 200px;
}

.lbl_uno { grid-area: lbl_uno; }

.lbl_dos { grid-area: lbl_dos; }

.lbl_tres { grid-area: lbl_tres; }



.grid-container_chart_1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "lbl_chart_1";
}

.lbl_chart_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "lbl_value lbl_image";
  grid-area: lbl_chart_1;
}

.lbl_value { grid-area: lbl_value; }

.lbl_image { grid-area: lbl_image; }

.lbl_chart_2 {
   width: 100%;
   height: 500px;
   align-content:center
}
.lbl_chart_2_sub {
   width: 95%;
   height: 80%;
   align-content:center
}


.button_v1 {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button_v2 {
  background-color: red; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.select-css {
    width: 100%;
    text-align: center;
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
     linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight:normal;
}


.ex2 .parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ex2 .box {
  flex: 1 1 250px; /*  Stretching: */
  flex: 0 1 250px ; /*  No stretching: */
  margin: 5px;
  height: 250px;
  border: solid 2px #000000;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #828080;
}

.boton_item {
    height: 100px;
    display: inline-block;
    /*float : left;*/
    width: 16%;
    margin: 1px;
}
.boton_item_span {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  border: 1px solid #FFD700;
  background: white;
  color: #4c0949;
  font-size: 18px;
}

ul.ks-cboxtags {
  list-style: none; 
}
ul.ks-cboxtags li{
display: inline;
}
ul.ks-cboxtags li label{
  display: inline-block;
  background-color: rgba(255, 255, 255, .9);
  border: 2px solid rgba(139, 139, 139, .3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
}

ul.ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "-";
  transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
  content: "+";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
  border: 2px solid #1bdbf8;
  background-color: #12bbd4;
  color: #fff;
  transition: all .2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
display: none;
}
ul.ks-cboxtags li input[type="checkbox"] {
position: none;
opacity: 0;
}
ul.ks-cboxtags li input[type="checkbox"]:focus + label {
border: 2px solid #e9a1ff;
}





ul.ks-cboxtagsface {
 /* list-style: none; */
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
ul.ks-cboxtagsface li{
/*display: inline;*/
    flex: 0 0 calc(9.08% - 10px); /* Ajustar el ancho de cada li */
    margin: 0px; /* Espacio entre cada li */
    background-color: white;
    border: 0px solid #ddd;
    padding: 0px;
    box-sizing: border-box;
    height: 80px;
}
ul.ks-cboxtagsface li label{
  height: 100%;
  display: inline-block;
  background-color: rgba(255, 255, 255, .9);
  border: 0px solid rgba(139, 139, 139, .3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
}
ul.ks-cboxtagsface li label img{
   /*display: none;*/
}

ul.ks-cboxtagsface li label {
  padding: 0px 0px;
  cursor: pointer;
}

ul.ks-cboxtagsface li label::before {
  /*display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "-";
  transition: transform .3s ease-in-out;*/
}

ul.ks-cboxtagsface li input[type="checkbox"]:checked + label::before {
  content: "";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

ul.ks-cboxtagsface li input[type="checkbox"]:checked + label {
  border: 0px solid #1bdbf8;
  color: #fff;
  transition: all .2s;
  height: 100px;
}
ul.ks-cboxtagsface li input[type="checkbox"]:checked + label  font {
  display: none;
}
ul.ks-cboxtagsface li input[type="checkbox"]:checked + label  img {
  display: inline;
}

ul.ks-cboxtagsface li input[type="checkbox"] {
display: none;
}
ul.ks-cboxtagsface li input[type="checkbox"] {
position: none;
opacity: 0;
}
ul.ks-cboxtagsface li input[type="checkbox"]:focus + label {
border: 2px solid #e9a1ff;
}

.imagen-recortada {
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.imagen-recortada img {
  
}


@media (max-width: 550px) { /* Ajustar ancho en pantallas más pequeñas */
  ul.ks-cboxtagsface li {
    height: 80px; /* Ancho completo menos margen */
  }
  ul.ks-cboxtagsface li input[type="checkbox"]:checked + label {
    border: 0px solid #1bdbf8;
    color: #fff;
    transition: all .2s;
    height: 85px;
  }
}
@media (max-width: 500px) { /* Ajustar ancho en pantallas más pequeñas */
  ul.ks-cboxtagsface li {
    height: 70px; /* Ancho completo menos margen */
  }
  ul.ks-cboxtagsface li input[type="checkbox"]:checked + label {
    border: 0px solid #1bdbf8;
    color: #fff;
    transition: all .2s;
    height: 80px;
  }
}

@media (max-width: 470px) { /* Ajustar ancho en pantallas aún más pequeñas */
  ul.ks-cboxtagsface li {
    height: calc(70px - ((470px - 100vw) / 470 * 74));
  }
  ul.ks-cboxtagsface li input[type="checkbox"]:checked + label {
    border: 0px solid #1bdbf8;
    color: #fff;
    transition: all .2s;
    height: calc(80px - ((470px - 100vw) / 470 * 84));
  }
}


td {
  padding: 10px;
  position: relative;
}

/* Línea curva */
.formato_das td:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(to bottom right, transparent calc(50% - 1px), black calc(50% - 1px), black calc(50% + 1px), transparent calc(50% + 1px));*/
  border-radius: 10px;
  pointer-events: none; /* Evita que la línea curva bloquee los eventos de ratón */
}

/* Sombra */
.formato_das td:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(211 223 222 / 40%);/*rgba(0, 0, 0, 0.2);*/
  border-radius: 10px;
  z-index: -1; /* Coloca la sombra detrás del td */
}

.buttonsurvey {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50; /* Fondo verde */
  color: white; /* Texto blanco */
  border-radius: 10px; /* Borde redondeado */
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border: none; /* Sin borde */
}

/* Estilo hover */
.buttonsurvey:hover {
  background-color: #45a049; /* Cambia el color de fondo en hover */
}
.alertasurvey {
  background-color: #ffe6e6; /* Rojo pastel claro */
  color: #cc0000; /* Color rojo más oscuro para el texto */
  border: 1px solid #cc0000; /* Borde del mismo color que el texto */
  padding: 15px; /* Espaciado interno */
  margin: 10px 0; /* Espaciado externo superior e inferior */
  border-radius: 5px; /* Bordes redondeados */
  font-family: Arial, sans-serif; /* Fuente para el texto */
  font-size: 16px; /* Tamaño de la fuente */
}
.textInputsurvey {
  padding: 10px; /* Espacio interno */
  border: 1px solid #007bff; /* Borde de 1px de color azul */
  border-radius: 10px; /* Borde redondeado */
  background-color: #e7f1fd; /* Fondo celeste */
  box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
  width: 300px; /* Ancho del input */
  font-size: 16px; /* Tamaño de la fuente */
}

.textInputsurveyv2 {
  border: none; /* Elimina todos los bordes */
  border-bottom: 2px solid gray; /* Añade solo el borde inferior de color gris */
  padding: 5px; /* Espacio dentro del input */
  outline: none; /* Elimina el borde azul al seleccionar el input */
}

.textInputsurveyv2:focus {
  border-bottom-color: darkgray; /* Cambia el color del borde inferior al hacer foco */
}
/* Oculta los botones de radio */
.radiosurvey input[type="radio"] {
  display: none;
}

/* Estilo de las etiquetas */
.radiosurvey label {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  background-color: #007bff; /* Color de fondo azul para "Sí" */
  color: white; /* Texto blanco */
  border-radius: 5px; /* Borde redondeado */
  cursor: pointer;
  text-align: center;
}

/* Cambia el color de fondo de la etiqueta seleccionada */
.radiosurvey input[type="radio"]:checked + label {
  background-color: #28a745; /* Color de fondo verde para "No" */
}
.survey-style1{
  width: 300px;
  height: 30px; /* Puedes ajustar el tamaño según tus necesidades */
  background-image: url('/LOCALIZADOR.png');
  background-size: cover; /* Ajusta la imagen para cubrir todo el div */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray; /* Color de texto blanco para mayor contraste */
  text-align: center;
}

.survey-style2{
  border: 2px solid #dedede; /* Borde gris */
  border-radius: 15px; /* Bordes redondeados */
  padding: 10px; /* Espacio interno del div */
  margin: 10px; /* Espacio externo del div */
  color: gray;
}

.survey-style3 {
  width: 108px; /* Ancho de la imagen */
  height: auto; /* Altura automática para mantener la proporción */
  cursor: pointer; /* Cambia el cursor al pasar sobre la imagen */
}

.survey-style3:focus {
  outline: none; /* Elimina el contorno al enfocarse en la imagen */
}

.survey-style4 {
  /* Ajusta este valor para definir la cantidad de sangría */
   /* Margen alrededor del párrafo */
  font-size: 16px; /* Tamaño de la fuente */
  line-height: 1.5; /* Altura de la línea para mejorar la legibilidad */
 
}


/* Checkbox.css */
.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 60px; /* Adjust height as needed */
  margin: 10px;
  border: 2px solid green;
  border-radius: 10px; /* Adjust border radius as needed */
  padding: 5px;
  box-sizing: border-box;
}

.checkbox-input {
  display: none;
}

.checkbox-custom {
  width: 50px;
  height: 50px;
  background-size: cover; 
  border-radius: 5px;
  margin-right: 10px;
}

.checkbox-text {
  font-size: 16px;
}

.checkbox-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
}










/*

Copiar código
ul.ks-cboxtagsv2 {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.ks-cboxtagsv2 li {
  display: inline-block;
  margin: 3px;
}

ul.ks-cboxtagsv2 li label {
  display: inline-flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  padding: 8px 12px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtagsv2 li label::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: px;
  background-size: cover;
  margin-right: 6px;
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtagsv2 li input[type="checkbox"]:checked + label::before {
  background-image: url('/CIRCULO_VERDE_GRIS_50X50.png');  
  transform: rotate(-360deg);
}

ul.ks-cboxtagsv2 li input[type="checkbox"] + label::before {
  background-image: url('/CIRCULO_GRIS_50X50.png');  
}

ul.ks-cboxtagsv2 li input[type="checkbox"]:checked + label {
  border: 2px solid green;
  color: gray;
}

ul.ks-cboxtagsv2 li input[type="checkbox"] {
  display: none;
}

ul.ks-cboxtagsv2 li input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff;
}
*/

ul.ks-cboxtagsv2 {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.ks-cboxtagsv2 li {
  display: inline-block;
  margin: 3px;
}

ul.ks-cboxtagsv2 li label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify; /* Justifica el texto */
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  padding: 8px 12px;
  cursor: pointer;
   
  box-sizing: border-box; /* Ensure padding is included in the width */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtagsv2 li label span {
  display: inline-block; /* Permite que el texto se ajuste al tamaño del contenedor */
  width: 100%; /* Asegura que el texto se ajuste al ancho del contenedor */
}

ul.ks-cboxtagsv2 li label::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 6px;
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtagsv2 li input[type="checkbox"]:checked + label::before {
  background-image: url('/CIRCULO_VERDE_GRIS_50X50.png');  
  transform: rotate(-360deg);
}

ul.ks-cboxtagsv2 li input[type="checkbox"] + label::before {
  background-image: url('/CIRCULO_GRIS_50X50.png');  
}

ul.ks-cboxtagsv2 li input[type="checkbox"]:checked + label {
  border: 2px solid green;
  color: gray;
}

ul.ks-cboxtagsv2 li input[type="checkbox"] {
  display: none;
}

ul.ks-cboxtagsv2 li input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff;
}