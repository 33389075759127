.grid-container-upload {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 10px 35px 10px;
  gap: 1px 1px;
  grid-template-areas: "lbl_image_upload" "lbl_progress_upload" "lbl_button_upload" "lbl_msg_upload";
}

.lbl_image_upload {
  grid-area: lbl_image_upload;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lbl_progress_upload {
  grid-area: lbl_progress_upload;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lbl_msg_upload {
  grid-area: lbl_msg_upload;
  display: flex;
  align-items: center;
  justify-content: center;
  color:red;
  font-size: 8pt;
}

.lbl_button_upload{
  grid-area: lbl_button_upload;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
